import React, { useState } from 'react';
import styled from 'styled-components';
import { Hide } from '../hide';
import { Link } from '../Link';
import { useTranslation } from 'react-i18next';

import { Box, Text, Flex, Card } from 'rebass';
import { Button } from '../Button';
import { Container } from '../container';
import shortid from 'shortid';

import AegeanLogo from './icons/aegean-logo-hero@3x.png';

import FindNearModal from '../FindNearModal';

function getUrl(img) {
  return ['url(' + img + ')'];
}

function getUpperHeight(height) {
  var halfHeight = height.replace('px', '') * 0.45;
  return halfHeight + 'px';
}

function getBottomHeight(height) {
  var halfHeight = height.replace('px', '') * 0.55;
  return halfHeight + 'px';
}

const HeroCardSend = ({ mobile, height, width, marginTop }) => {
  const { t } = useTranslation();

  return (
    <Box
      width={width}
      pb={mobile ? '20px' : ''}
      marginTop={marginTop}
      marginLeft={[0, 'auto', 0]}
    >
      <Box
        bg="white"
        css={{
          borderTopLeftRadius: '13px',
          borderTopRightRadius: '13px',
          minHeight: height ? getUpperHeight(height) : '',
        }}
      />
      <Flex
        px={['18px', '22px', '24px']}
        pt={['18px', '22px', '30px']}
        flexDirection="column"
        css={{
          position: 'relative',
          top: '-' + getUpperHeight(height),
          marginBottom: '-' + getUpperHeight(height),
          minHeight: height ? getUpperHeight(height) : '',
        }}
      >
        <Text
          width={[1, 1, 1]}
          pt={['0px', '2px', '4px']}
          pb={['12px', '2px', '12px']}
          color="blues.peacock"
          fontSize={['20px', '22px', '26px']}
          lineHeight={['1.3', '1.3', '1.18']}
          letterSpacing={['0.2px']}
          textAlign="left"
        >
          {t('hero.send_a_box.deliver_your_box_now')}
        </Text>
        <Flex flexDirection="row">
          <Text
            color="blues.peacock"
            fontSize={['14px', '14px', '14px']}
            fontWeight="600"
            lineHeight="1.86"
            textAlign="left"
          >
            {t('hero.send_a_box.from')}
          </Text>
          <Text
            width={1}
            marginLeft={['18px', '20px', '18px']}
            color="blues.twilight"
            fontSize={['40px', '50px', '57px']}
            fontWeight="bold"
            lineHeight="1"
            letterSpacing={['-1.5px', '-1.88px']}
            textAlign="left"
          >
            {t('hero.send_a_box.price_per_kg')}
          </Text>
        </Flex>
      </Flex>
      <Box
        bg="blues.veryLight"
        css={{
          borderBottomLeftRadius: '13px',
          borderBottomRightRadius: '13px',
          minHeight: height ? getBottomHeight(height) : '',
        }}
      />
      <Flex
        pt={['16px', '18px', '20px']}
        px={['18px', '22px', '24px']}
        flexDirection="column"
        justifyContent="space-between"
        css={{
          position: 'relative',
          top: '-' + getBottomHeight(height),
          marginBottom: '-' + getBottomHeight(height),
          minHeight: height ? getBottomHeight(height) : '',
        }}
      >
        <Text
          color="marine"
          fontSize={['13px', '13px', '15px']}
          lineHeight={['1.31', '1.31', '1.47']}
          letterSpacing={['0.3px', '0.6px', '1.2px']}
          textAlign="left"
        >
          {t('hero.send_a_box.content')}
        </Text>
        <Box pb="23px">
          <Link to="/my_boxit/sending">
            <Button
              width="100%"
              height="auto"
              marginTop="auto"
              fontSize="12px"
              fontWeight="900"
              lineHeight="1.75"
              letterSpacing="2px"
              padding={[
                '12px 12px 8px 12px',
                '12px 12px 8px 12px',
                '12px 0 9px',
              ]}
              border="none"
            >
              {t('hero.send_a_box.button')}
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export const HeroCardFindAegean = ({ mobile, height, width }) => {
  const [findNearModalOpen, setFindNearModalOpen] = useState(false);
  const [nearModalAddress, setNearModalAddress] = useState(null);
  const { t } = useTranslation();
  return (
    <HeroCard mobile={mobile} height={height} width={width}>
      <FindNearModal
        findNearModalOpen={findNearModalOpen}
        setFindNearModalOpen={setFindNearModalOpen}
        nearModalAddress={nearModalAddress}
        setNearModalAddress={setNearModalAddress}
      />
      <Flex flexDirection="column">
        <Flex
          width={1}
          pt={[0]}
          pb={['5px', '2px']}
          mx={mobile ? 'auto' : ''}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Text
            width={0.64}
            color="blues.twilight"
            fontSize="16px"
            fontWeight="bold"
            lineHeight={['1.19', '1.31']}
            textAlign="left"
            css={{ minHeight: '24px' }}
          >
            {t('hero.pickup_your_box.find_the_nearest')}
          </Text>
          {/* <Card
            width={[0.4, 0.31]}
            backgroundColor="none"
            backgroundImage={getUrl(AegeanLogo)}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="top center"
            css={{ mixBlendMode: 'darken' }}
          /> */}
        </Flex>
        <Text
          width={[1, 1]}
          pb={['15px', '18px', '5px']}
          color="blues.twilight"
          fontSize="16px"
          fontWeight="bold"
          lineHeight={['1.19', '1.31']}
          textAlign="left"
        >
          {t('hero.pickup_your_box.weboxit_delivery_point')}
        </Text>
      </Flex>

      <Flex>
        <Button
          width="100%"
          height={mobile ? '36px' : '40px'}
          marginTop="auto"
          fontSize="16px"
          fontWeight="bold"
          lineHeight="1.75"
          letterSpacing="normal"
          padding={['6px 10px 3px 10px', '7px 15px 3px 12px']}
          border="none"
          flatLeft={true}
          onClick={e => setFindNearModalOpen(true)}
        >
          {t('hero.pickup_your_box.button')}
        </Button>
      </Flex>
    </HeroCard>
  );
};

export const HeroCardLocateBox = ({ mobile, height, width }) => {
  const [trackingBoxId, setTrackingBoxId] = useState(null);
  const { t } = useTranslation();
  return (
    <HeroCard mobile={mobile} height={height} width={width}>
      <Text
        pb={['14px', '12px', '8px']}
        width={[1, 1, 1]}
        mx="auto"
        color="blues.twilight"
        fontSize={['16px', '16px', 3]}
        fontWeight="bold"
        letterSpacing="0.15px"
        lineHeight="1"
        textAlign="left"
      >
        {t('hero.box_tracking.locate_your_box')}
      </Text>

      {/* <Input
        placeholder={t('hero.box_tracking.placeholder')}
        onChange={e => setTrackingBoxId(e.target.value)}
      /> */}
      <Box pt={['13px', '14px', '60px']}>
        {/* <Link to={`/box_tracking?auto=true&boxId=${trackingBoxId || ''}`}> */}
        <a
          href="https://wbx-prod.qualco.eu/deliverd-webhosting/tracking"
          target="_blank"
        >
          <Button
            width="100%"
            height="auto"
            marginTop="auto"
            fontSize="12px"
            fontWeight="900"
            lineHeight="1.75"
            letterSpacing="2px"
            padding={['12px 12px 8px 12px', '12px 12px 8px 12px']}
            border="none"
          >
            {t('hero.box_tracking.button')}
          </Button>
        </a>
        {/* </Link> */}
      </Box>
    </HeroCard>
  );
};

const Input = styled.input`
  display: block;
  width: 100%;
  height: ${props => (props.mobile ? '36px' : '40px')};
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightBlueGrey};
  border-top-right-radius: ${props => (props.flatRight ? 0 : '')};
  border-bottom-right-radius: ${props => (props.flatRight ? 0 : '')};
  background-color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.35px;
  padding: ${props => (props.mobile ? '0 8px' : '0 14px')};
  color: #032e4f;
  ::placeholder {
    color: ${props => props.theme.colors.lightBlueGrey};
    font-size: 15px;
  }
`;

const HeroCard = ({ mobile, height = '165px', width, marginTop, children }) => (
  <Box width={width} pb={mobile ? '20px' : ''} marginTop={marginTop}>
    <Box
      bg={'blues.veryLight'}
      css={{
        minHeight: height ? height : '',
        borderRadius: '13px',
      }}
    />
    <Flex
      px={['18px', '25px', '25px']}
      py={['18px', '16px', '25px']}
      flexDirection="column"
      justifyContent="space-between"
      css={{
        position: 'relative',
        top: '-' + height,
        marginBottom: '-' + height,
      }}
    >
      {children}
    </Flex>
  </Box>
);

const HeroCards = ({ children, mobile = false }) => (
  <Flex
    width={['95%', '92%', '88%', '80%']}
    pt={['30px', '56px']}
    // mx={['20px', '4%', '6%', '10%']}
    mx="auto"
    justifyContent="space-between"
    flexDirection={mobile ? 'column' : 'row'}
    mt={['222px', '222px', '368px']}
    css={{ position: 'absolute', maxWidth: '1152px' }}
  >
    {children}
  </Flex>
);

const HeroHeader = ({
  title,
  subtitle,
  phrase,
  actions,
  customStyle = '',
  children,
}) => (
  <>
    <Flex flexWrap="wrap" flexDirection="row" width={1}>
      {(title || subtitle || phrase || actions) && <VerticalYellowLine />}
      <Flex
        width={[0.5, customStyle === 's1' ? 0.9 : 0.55, 0.9]}
        pl={[0, '12px', '20px']}
        pb={[
          0,
          customStyle === 's1' ? '35px' : '130px',
          customStyle === 's1' ? '65px' : '130px',
        ]}
        flexWrap="wrap"
        flexDirection="column"
      >
        {title && <HeroTitle customStyle={customStyle}>{title}</HeroTitle>}
        {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
        {phrase && <HeroPhrase customStyle={customStyle}>{phrase}</HeroPhrase>}
        {actions && (
          <Flex flexDirection={customStyle === 's1' ? 'row-reverse' : 'row'}>
            {actions &&
              actions.map(action => (
                <Box
                  paddingRight={[0, '11px', '20px']}
                  key={shortid.generate()}
                >
                  <Link to={action.link}>
                    <Button
                      height={[0, '32px', '40px']}
                      lineHeight="32px"
                      border="none"
                      fontSize="12px"
                      letterSpacing="2px"
                      padding={[
                        '13px 0 12px 0',
                        '1px 15px 0px 15px',
                        '6px 60px 4px 60px',
                      ]}
                    >
                      {action.name}
                    </Button>
                  </Link>
                </Box>
              ))}
          </Flex>
        )}
      </Flex>
      {children}
    </Flex>
  </>
);

const HeroHeaderMobile = ({
  title,
  subtitle,
  phrase,
  actions,
  customStyle = '',
}) => (
  <>
    {(title || subtitle || phrase || actions) && (
      <VerticalYellowLine customStyle={customStyle} />
    )}
    <Flex width={0.9} pl={4} flexWrap="wrap" flexDirection="column">
      {title && <HeroTitle customStyle={customStyle}>{title}</HeroTitle>}
      {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
      {phrase && <HeroPhrase customStyle={customStyle}>{phrase}</HeroPhrase>}
      {actions &&
        actions.map(action => (
          <Link key={action.name} to={action.link}>
            <HeroButtonMobile>{action.name}</HeroButtonMobile>
          </Link>
        ))}
    </Flex>
  </>
);

const HeroButtonMobile = ({ children }) => (
  <Button
    border="none"
    padding="10px 0"
    height="38px"
    width="100%"
    fontSize="12px"
    fontWeight="900"
    lineHeight="1.75"
    letterSpacing="2.3px"
  >
    {children}
  </Button>
);

const HeroPhrase = ({ customStyle = '', children }) => (
  <Text
    m="0"
    pt={[3, 4, 4]}
    pb={['9px', 2, 3]}
    color="white"
    fontSize={[4, 5, 7]}
    fontWeight="600"
    lineHeight={customStyle === 's1' ? '1' : '0'}
    wrap="wrap"
    letterSpacing={['normal', '-0.2px']}
  >
    {children}
  </Text>
);

const HeroSubtitle = ({ children }) => (
  <Text
    m="0"
    pb={['21px', 4, 5]}
    color="white"
    fontSize={[6, 8, 9]}
    fontWeight="900"
    lineHeight="0"
    letterSpacing="-0.2px"
  >
    {children}
  </Text>
);

const HeroTitle = ({ customStyle = '', children }) => (
  <Text
    p="0"
    pt={[
      customStyle === 's1' ? '30px' : 3,
      customStyle === 's1' ? '40px' : '13px',
      customStyle === 's1' ? '60px' : 0,
    ]}
    pb={[2, 1, 1]}
    mb={[
      customStyle === 's1' ? '-10px' : '0',
      customStyle === 's1' ? '-20px' : '0',
      customStyle === 's1' ? '-25px' : '0',
    ]}
    color="white"
    fontSize={[
      customStyle === 's1' ? '60px' : '105px',
      '105px',
      customStyle === 's1' ? '144px' : '187px',
    ]}
    fontWeight="bold"
    lineHeight="1.05"
    letterSpacing="-7px"
  >
    {children}
  </Text>
);

const VerticalYellowLine = ({ customStyle = '', children }) => (
  <Box
    m="0"
    mt={['0px', '4px', '14px']}
    py={[customStyle === 's1' ? '110px' : '138px', '105px', '150.5px']}
    width={['8px', '8px', '12px']}
    bg="maize"
    css={{
      height: '0',
      borderRadius: 5,
      display: 'inline-block',
    }}
  />
);

const HeroHorizontalLine = styled.div`
  height: 6px;
  background-image: linear-gradient(to bottom, #ffd727, #d9ba34);
  max-width: 1440px;
  margin: auto;
`;

const Hero = ({
  bgImg,
  title,
  subtitle,
  phrase,
  actions,
  showCards = false,
  showHorizontalLine = false,
  customStyle = '',
  children,
}) => (
  <>
    <Card
      pt={[
        customStyle === 's1' ? '30px' : '115px',
        customStyle === 's1' ? '30px' : '145px',
        customStyle === 's1' ? '50px' : '145px',
      ]}
      backgroundImage={'url(' + bgImg + ')'}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={[
        'top center',
        customStyle === 's1' ? 'top center' : 'top left',
      ]}
      css={{
        minHeight: customStyle === 's1' ? '280px' : '500px',
        maxWidth: '1440px',
      }}
      mx="auto"
    >
      <Container>
        <Hide tablet desktop>
          <Flex
            p="0"
            flexWrap="wrap"
            flexDirection="row"
            alignItems="flex-start"
            css={{ height: customStyle === 's1' ? '240px' : '394px' }}
          >
            <HeroHeaderMobile
              title={title}
              subtitle={subtitle}
              phrase={phrase}
              actions={actions}
              customStyle={customStyle}
            />
            <Box
              mr="5%"
              mt="310px"
              css={{
                display: 'inline-flex',
                position: 'absolute',
              }}
            >
              {showCards && <HeroCardSend mobile="true" height="252px" />}
            </Box>
          </Flex>
        </Hide>
        <Hide mobile desktop>
          <Flex flexWrap="wrap" flexDirection="row" alignItems="flex-start">
            <HeroHeader
              title={title}
              subtitle={subtitle}
              phrase={phrase}
              actions={actions}
              customStyle={customStyle}
              children={children}
            >
              {showCards && <HeroCardSend width="303px" height="252px" />}
            </HeroHeader>
            {showCards && (
              <HeroCards>
                <HeroCardLocateBox width={1 / 2.03} />
                <HeroCardFindAegean width={1 / 2.03} />
              </HeroCards>
            )}
          </Flex>
        </Hide>
        <Hide mobile tablet>
          <Flex
            flexWrap="wrap"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            css={{ height: customStyle === 's1' ? 'auto' : '566px' }}
          >
            <HeroHeader
              title={title}
              subtitle={subtitle}
              phrase={phrase}
              actions={actions}
              customStyle={customStyle}
            />
            {showCards && (
              <HeroCards>
                <HeroCardLocateBox width={1 / 3.18} height="165px" />
                <HeroCardFindAegean width={1 / 3.18} height="165px" />
                <HeroCardSend
                  width={1 / 3.18}
                  height="304px"
                  marginTop="-137px"
                />
              </HeroCards>
            )}
          </Flex>
        </Hide>
      </Container>
    </Card>
    {children}
    {showHorizontalLine && <HeroHorizontalLine />}
    <Container bg="blues.twilight">
      <Hide tablet desktop>
        <Flex
          flexWrap="wrap"
          flexDirection="row"
          alignItems="flex-start"
          paddingTop={customStyle === 's1' ? '30px' : '190px'}
          css={{ height: customStyle === 's1' ? '0' : '570px' }}
        >
          {showCards && (
            <>
              <HeroCardLocateBox mobile="true" width={1} />
              <HeroCardFindAegean mobile="true" width={1} height="158px" />
            </>
          )}
        </Flex>
      </Hide>
    </Container>
  </>
);

export default Hero;
