import React from 'react';
import { useTranslation } from 'react-i18next';
import BoxitModal from '../../components/Modal';
import SelectDeliveryPoing from '../CreateBoxModal/SelectDeliveryPoint'

export default function CreateBoxModal({ 
    findNearModalOpen, 
    setFindNearModalOpen, 
    nearModalAddress,
    ...props }
    ) {
    
    const { t } = useTranslation();
    return(
        <BoxitModal
        title={t('my_boxit.findNearest.title')}
        width={['100%', '100%', '1120px']}
        minHeight={['100%', '100%', '520px']}
        isOpen={findNearModalOpen}
        onCloseButtonClick={() => setFindNearModalOpen(false)}
        onEscapeKeydown={() => setFindNearModalOpen(false)}
        >
            <SelectDeliveryPoing
                isTracking
                defaultSearchValue={nearModalAddress}
            />
        </BoxitModal>
    )
}
  