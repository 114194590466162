import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import { EasySteps } from '../components/EasySteps';
import { swiperParams } from '../theme';

import HeroBackgroundImageXL from '../images/hero-image-3@3x.jpg';

const SendABoxPage = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="SEND A NEW BOX" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('send_new_box.header.title')}
        subtitle={t('send_new_box.header.subtitle')}
      />
      <Hero
        title={t('send_new_box.hero.title')}
        phrase={t('send_new_box.hero.subtitle')}
        bgImg={HeroBackgroundImageXL}
        actions={[
          { name: t('send_new_box.hero.action'), link: '/my_boxit/sending' },
        ]}
        customStyle="s1"
      />
      <EasySteps params={{ swiperParams }} customStyle="s1" />
    </Layout>
  );
};

export default SendABoxPage;
