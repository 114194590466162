import React from 'react';
import { Box, Text, Heading } from 'rebass';
import { Map } from '../Map';
import { Hide } from '../hide';
import { useTranslation } from 'react-i18next';

const SelectDeliveryPoint = ({ onMarkerSelected, defaultSearchValue, selectedMarker, isTracking }) => {
  const { t } = useTranslation();
  return (
    <Box width={1}>
      <Heading
        fontSize={['12px', '20px', '25px']}
        fontWeight="normal"
        lineHeight="1.04"
        letterSpacing="0.2px"
        color="blues.peacock"
      >
        {t('my_boxit.create_box_modal.select_delivery_point.header')}
      </Heading>
      {!isTracking && 
        <Text pt="4px" fontSize="12px" color="blues.peacock">
          {t('my_boxit.create_box_modal.select_delivery_point.description')}
        </Text>
      }

      <Hide tablet desktop>
        <Map
          id="delivery-point"
          height="240px"
          fullWidthMap={true}
          showSearchBox={true}
          isMobile={true}
          searchButtonText={!isTracking ? t(
            'my_boxit.create_box_modal.select_delivery_point.search_button_text') : ''
          }
          chooseLabelText={t(
            'my_boxit.create_box_modal.select_delivery_point.choose_label'
          )}
          showUserLocation={true}
          autoLoadPickupPoints={true}
          searchBoxPlaceholder={t(
            'my_boxit.create_box_modal.select_delivery_point.search_box_placeholder'
          )}
          onMarkerSelected={onMarkerSelected}
          selectedMarker={selectedMarker || null}
          defaultSearchValue={defaultSearchValue}
        />
      </Hide>

      <Hide mobile>
        <Map
          id="delivery-point"
          height="280px"
          splitAt="57%"
          showInfoWindow={true}
          showSearchBox={true}
          searchButtonText={t(
            'my_boxit.create_box_modal.select_delivery_point.search_button_text'
          )}
          chooseLabelText={!isTracking ? t(
            'my_boxit.create_box_modal.select_delivery_point.choose_label'
          ): ''}
          showUserLocation={true}
          autoLoadPickupPoints={true}
          searchBoxPlaceholder={t(
            'my_boxit.create_box_modal.select_delivery_point.search_box_placeholder'
          )}
          onMarkerSelected={onMarkerSelected}
          selectedMarker={selectedMarker || null}
          defaultSearchValue={defaultSearchValue}
        />
      </Hide>
    </Box>
  );
};

export default SelectDeliveryPoint;
